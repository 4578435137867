import { MaterialInput } from '@/components/utilities/MaterialInput'
import WifModal from '@/components/WifModal'
import LoginLayout from '@/layouts/LoginLayout'
import { LoginSchema } from '@/lib/schema/LoginFormSchema'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AuthContext } from 'providers/AuthProvider'
import React, { useEffect } from 'react'
import {
  CustomerAccessToken,
  CustomerErrorCode,
  CustomerUserError,
  useUserLoginMutation,
} from 'shopify/api'

const Login: React.FC<{ from: string; redirectURL?: string }> = ({
  from,
  redirectURL,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter()
  const { setAuthToken, authToken } = React.useContext(AuthContext)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isResetPasswordModal, setIsResetPasswordModal] = React.useState<boolean>(false)
  const [isNeedLoginMessage, setIsNeedLoginMessage] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')

  const [loginUser] = useUserLoginMutation()

  const onLogin = async () => {
    setIsLoading(true)
    try {
      const { data } = await loginUser({
        variables: {
          input: {
            email: formik.values.email,
            password: formik.values.password,
          },
        },
      })
      const responseErrors = data?.customerAccessTokenCreate
        ?.customerUserErrors as CustomerUserError[]
      const responseData = data?.customerAccessTokenCreate
        ?.customerAccessToken as CustomerAccessToken

      if (responseErrors.length < 1) {
        setAuthToken(responseData.accessToken, {
          expires: new Date(responseData.expiresAt),
        })
        return router.push(redirectURL || '/')
      }

      if (
        responseErrors.length > 0 &&
        responseErrors[0].code === CustomerErrorCode.UnidentifiedCustomer
      ) {
        setIsLoading(false)
        return setErrorMessage(t('register:Error.Unidentified_User'))
      }

      if (responseErrors.length > 0) {
        setIsLoading(false)
        return setErrorMessage(responseErrors[0].message)
      }
    } catch (err: any) {
      if (err.message) setErrorMessage(err.message)

      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: onLogin,
    validationSchema: LoginSchema,
  })

  useEffect(() => {
    if (authToken) router.push(process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN!)
  }, [authToken])

  useEffect(() => {
    if (router.query.success === 'reset_password') setIsResetPasswordModal(true)
    if (router.query.errorAuth) setIsNeedLoginMessage(true)
  }, [router.query])

  return (
    <LoginLayout browserTitle='WifKain - Login' title='Login' from={from}>
      <div
        className={clsx(
          'sm:pb-9 sm:px-4 bg-white flex flex-col justify-start rounded-3xl w-full sm:max-w-[430px] ',
          'pb-6 max-w-[90%] px-2 mt-10 mb-4'
        )}
      >
        <div className='flex items-center justify-center mt-4 mb-8'>
          <Link href={'/'}>
            <Image src='/icons/wif-logo_large.svg' alt='Wif Logo' height={44} width={116} />
          </Link>
        </div>
        {isNeedLoginMessage && (
          <span className='text-center w-full max-w-[320px] mx-auto text-sm font-normal my-5'>
            {t('register:No_Auth_Message')}
          </span>
        )}

        <form onSubmit={formik.handleSubmit} data-test='login-form' className='mt-8'>
          <MaterialInput
            name='email'
            label='Email'
            type='email'
            value={formik.values.email}
            error={!!formik.errors?.email && !!formik.touched?.email}
            errorMessage={t(formik.errors.email!)}
            icon='/icons/ic-mail.svg'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <MaterialInput
            name='password'
            label='Password'
            type='password'
            value={formik.values.password}
            error={!!formik.errors?.password && !!formik.touched?.password}
            errorMessage={t(formik.errors.password!)}
            icon='/icons/ic-key.svg'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <p
            className='text-sm text-right mb-12 -mt-4 text-grey-3'
            dangerouslySetInnerHTML={{ __html: t('register:Forgot_Password') }}
          />

          <button
            type='submit'
            className={clsx(
              'bg-primary text-white font-bold text-sm py-[18px] w-full rounded-[10px] cursor-pointer',
              'shadow-[0px_4px_14px_0px_rgba(27,71,130,0.19)]',
              'disabled:bg-[#F3F3F3] disabled:shadow-[0px_2px_10px_0px_rgba(183,183,183,0.2)] disabled:text-[#676767]'
            )}
            disabled={!(formik.isValid && formik.dirty) || isLoading}
            data-test='register-button-submit'
          >
            Login
          </button>
          {!!errorMessage && (
            <span className='mt-1 text-error-red text-xs font-semibold'>
              {t(errorMessage, { interpolation: { alwaysFormat: true } })}
            </span>
          )}

          <div className='flex items-center w-full justify-center gap-6 mt-16'>
            <span>{t('register:Dont_Have_Account')}</span>
            <a
              href='/register'
              className='text-wif-orange font-bold'
              data-test='login-link-register'
            >
              Register
            </a>
          </div>
        </form>
      </div>

      {isResetPasswordModal && (
        <WifModal
          id='reset-password'
          bodyText={t('register:Modal.Forgot_Password_Body')}
          onMainAction={() => setIsResetPasswordModal(false)}
          actions
        />
      )}
    </LoginLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale, req, query }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['register', 'navbar', 'common'])),
      from: req.headers.referer || '',
      redirectURL: query?.from || '',
    },
  }
}

export default Login
