import AppContainer from '@/layouts/AppContainer'
import Image from 'next/image'
import { PropsWithChildren, useEffect } from 'react'
import WifButton from './WifButton'

interface WifModalProps {
  bodyText?: string
  actions?: boolean
  closeButton?: boolean
  mainActionText?: string
  secondaryActionText?: string
  onClose?: () => void
  onMainAction?: () => void
  id: string
  title?: string
  // might be a good idea to change using image component instead
  imageUrl?: string
  imageSize?: number
  imageAlt?: string
}

const WifModal: React.FC<PropsWithChildren<WifModalProps>> = ({
  actions = true,
  mainActionText,
  bodyText = true,
  secondaryActionText,
  onClose,
  onMainAction,
  id,
  title,
  imageUrl,
  imageAlt = '',
  imageSize,
  children,
}) => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <AppContainer>
      <div className='h-screen fixed w-full top-0 z-50 left-0 right-0 bg-grey/80 flex items-center'>
        {children ? (
          <div
            data-test={`wif-modal-${id}`}
            className='flex justify-center items-center bg-white shadow-[0px_0px_34px_0px_rgba(0,0,0,0.15)] w-full max-w-[380px] mx-auto rounded-[32px] min-h-[120px]'
          >
            {children}
          </div>
        ) : (
          <div
            data-test={`wif-modal-${id}`}
            className='bg-white p-8 pt-12 shadow-[0px_0px_34px_0px_rgba(0,0,0,0.15)] relative w-full max-w-[380px] mx-auto rounded-[32px] overflow-hidden'
          >
            {imageUrl && (
              <div className='flex items-center justify-center mb-4 w-full text-center'>
                <Image src={imageUrl} width={imageSize} height={imageSize} alt={imageAlt} />
              </div>
            )}
            {title && <span className='block text-center font-bold text-sm mb-1'>{title}</span>}
            {bodyText && <div className='text-center font-normal text-sm mb-8'>{bodyText}</div>}
            {actions && (
              <div className='flex flex-col gap-4'>
                {onMainAction && (
                  <WifButton
                    type='primary'
                    id='modal-approve-action'
                    text={mainActionText || 'OK'}
                    handleClick={onMainAction}
                  />
                )}
                {onClose && (
                  <WifButton
                    border={false}
                    type='secondary'
                    id='modal-cancel-action'
                    text={secondaryActionText || 'Batal'}
                    handleClick={onClose}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </AppContainer>
  )
}

export default WifModal
