import * as Yup from 'yup'

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('register:Error.Email_Format')
    .required('register:Error.Email_Required'),
  password: Yup.string()
    .min(8, 'register:Error.Password_Minimum')
    .required('register:Error.Password_Required'),
})
