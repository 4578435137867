import NavBar from '@/components/NavBar'
import Head from 'next/head'
import { PropsWithChildren, ReactNode } from 'react'
import AppContainer from './AppContainer'

interface SimplifiedLayoutProps {
  from: string
  browserTitle: string
  title: string
  withExtraLevel?: boolean
  closeButton?: boolean
  customAction?: ReactNode
  handleClose?: () => void
  extraLevelMargin?: number
}

const SimplifiedLayout: React.FC<PropsWithChildren<SimplifiedLayoutProps>> = ({
  children,
  from,
  browserTitle,
  title,
  closeButton = false,
  handleClose,
  customAction,
}) => {
  return (
    // Overlaying a separate background over everything just to simplify development and not interfere with shared components
    <div className='h-screen w-screen bg-[url("/images/bg-login.png")] bg-no-repeat bg-center bg-cover overflow-y-auto'>
      <AppContainer transparent={true}>
        <Head>
          <title>{browserTitle}</title>
        </Head>
        <NavBar
          simplified
          title={title}
          from={from}
          rightActions={[]}
          backButton={!closeButton}
          handleClose={handleClose}
          children={customAction}
        />
        <div className='flex items-center justify-center pt-[60px] '>{children}</div>
      </AppContainer>
    </div>
  )
}

export default SimplifiedLayout
